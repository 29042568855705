:root {
    --font: "hk_groteskregular";
    --font-title: "hk_grotesksemibold";
    --primary: #002544!important;
    --main-color: #002544;
    --blue: #002544;
    --links-color: #002544;
    --resize-btn-color: #002544;
    --footer-bg: #515151;
    --footer-bg-darken: #414141;
    --important-bg: #f6faff;
    --important-title-color: #424242;
    --important-text-color: #424242;
}